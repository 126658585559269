import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_9/b9_1.webp");
const image2 = require("../../../assets/images/blog_9/b9_2.png");
const image3 = require("../../../assets/images/blog_9/b9_3.jpg");
const image4 = require("../../../assets/images/blog_9/b9_4.png");
const image5 = require("../../../assets/images/blog_9/b9_5.jpg");
const image6 = require("../../../assets/images/blog_9/b9_6.jpg");
const image7 = require("../../../assets/images/blog_9/b9_7.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Integrate Deputy with Chatbot and automate HR support | Workativ Blog"
        description="Learn how Deputy customers are automating repetitive HR tasks and streamline employee enquiries by building Deputy HR chatbot."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Deputy integration and workflow automation |
                            Workativ
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          COVID-19 has forced entire offices to work from home
                          almost overnight and consumers to change their
                          behaviors. This has left HR and internal help desk
                          teams struggling to cope with a deluge of queries and
                          requests, often from across time zones.
                          <a href="https://blog.workativ.com/get-over-the-pandemic-standstill-leapfrog-with-digital-assistants-that-enable-business-continuity-during-covid-19/">
                            &nbsp;Forward-thinking companies have turned to
                            chatbots to ease the load.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With employees telecommuting and staff numbers
                          depleted due to self-isolation,{" "}
                          <a href="https://blog.workativ.com/chatbot-best-practices-for-designing-a-conversational-experience-with-workativ-assistant/">
                            chatbots
                          </a>{" "}
                          have emerged as a key support system to help desk and
                          HR teams. The technology deals with first-line
                          inquiries, providing automated answers powered by
                          artificial intelligence. This frees up teams to tackle
                          more complex questions and keeps information up to
                          date. The latter is extremely important in a pandemic
                          where information and advice are changing fast.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Adapting to drastic changes can be arduous at first.
                          The increasing need for integration between employees,
                          their peers and their managers, means that HR and IT
                          teams must centralize and easily manage communication
                          processes to ensure coordination between teams.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          These processes must be implemented quickly and easily
                          so that HR teams can deploy them without technical
                          problems.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So, how can you do all these and make it easier on
                          your HR personnel as well as your help desk team? Why,
                          with Workativ of course!
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ is an AI-powered no-code workflow automation
                          platform for building contextual chatbots with
                          automated workflows for various business apps.
                          Workativ’s chatbots are easy to add to your business’
                          communications hub like{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            Slack
                          </a>{" "}
                          or{" "}
                          <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                            Microsoft Teams.&nbsp;
                          </a>
                          Employees can self-serve their IT issues on-the-go by
                          just having a quick chat with Workativ’s chatbot
                          without logging in and out of a convoluted
                          self-service portal. Sounds simple, right?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If your business is using HR tools like Deputy for
                          workforce management, it’s high-time you connected it
                          with Workativ and transformed it into a conversational
                          self-service platform via Workativ’s chatbots.
                        </p>
                        <h2 class="font-section-sub-header-small line-height-2">
                          HR Workflow Automation with Deputy and Workativ
                        </h2>
                        <img loading="lazy" src={image2} className="mb-5"></img>
                        <p class="font-section-normal-text line-height-2">
                          Here are some of the Deputy tasks that Workativ can
                          take care of for you.
                        </p>
                        <h5 class="font-section-sub-header-small line-height-2">
                          1. Onboarding/Offboarding
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Manual Onboarding/Offboarding of employees on Deputy
                          involves not only the employee but also the HR
                          personnel, with the same information being entered
                          repeatedly – multiplying the chances of manual errors.
                          Automating the process with Workativ’s Deputy AI
                          chatbot ensures that employee information (i.e. name,
                          date of birth, social security number, etc.) gathered
                          from the HR by the chatbot is loaded into the various
                          fields so that HR need not repeatedly fill in the same
                          information. Automated processes can also help ensure
                          that an employee’s departure from the organization is
                          handled efficiently and tactfully.
                        </p>
                        <h5 class="font-section-sub-header-small line-height-2">
                          2. Deputy Payroll
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Managing and assisting employees in knowing about
                          their salary is one of the central tasks of HR. Not
                          only is it important to have a salary structure that
                          makes employees happy, but it is also imperative to
                          communicate information on compensation that’s clear
                          and easy to understand. There’s a significant
                          convenience factor in having automated employee salary
                          administration processes in place. This is especially
                          true if a business operates across multiple states, if
                          part of the workforce operates remotely, or if
                          employees are allowed to work flexible schedules.
                          Workativ’s Deputy chatbot takes care of this by
                          <a href="https://workativ.com/use-cases/payroll-enquiry-automation">
                            {" "}
                            allowing employees to view their pay details
                          </a>{" "}
                          on their Slack or Microsoft Teams app, anywhere,
                          anytime.
                        </p>
                      </div>
                      <div>
                        <h5 class="font-section-sub-header-small">
                          Workativ’s Deputy Integrations that’ll make Employee
                          Onboarding and Off-boarding easier
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Not only can you automate Deputy tasks with Workativ,
                          but you can also combine Deputy tasks with other app
                          tasks into one single effective automation. Let’s take
                          a look at some of them.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. Efficient Deputy Employee Onboarding/Offboarding
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          If you are still using manual methods to onboard your
                          new hires, think again. It becomes important now than
                          ever to give an excellent onboarding experience
                          because numerous studies show that the onboarding
                          period is when your new hires decide whether to stay
                          or leave your company. This means exactly what you
                          think, that all your efforts, physically, mentally,
                          and mainly monetarily will see its fruits only if your
                          new hires get an employee experience like no other
                          during the onboarding process.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Trying to achieve this excellence through manual mode
                          and with no help from intelligent technologies is
                          basically decreasing your chances of being excellent
                          or even mediocre.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          So what does this mean for all the HR folks reading
                          this? We have to use smart tools (Workativ) to make
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            &nbsp;Onboarding
                          </a>{" "}
                          effective which directly improves retention rates and
                          thus makes sense for all the effort put in hiring that
                          candidate.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          You can set up an automation on Workativ after
                          connecting your Deputy, Office 365, and Slack account
                          such that the chatbot will add the employee to the
                          company’s Office 365 email distribution list after
                          retrieving the employees’ email from Deputy and then
                          notifies everyone on the company’s Slack channel about
                          the new employee via message. And when an employee
                          gets terminated, help desk agents can use Workativ’s
                          chatbot to create a ticket on tools like Jira so that
                          the employee experiences a smooth{" "}
                          <a href="https://workativ.com/use-cases/employee-offboarding-automation">
                            offboarding&nbsp;
                          </a>
                          experience.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          2. Timesheet Details
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Timesheet automation is the process of capturing and
                          tracking employee working hours easily through an
                          application (Workativ’s Chatbot) that works on
                          multiple platforms seamlessly (mobile phones, desktop,
                          and browser extensions). Employee timesheet automation
                          simplifies and speeds up timesheet processing and
                          changes the way timesheets travel through the
                          organization to the payroll process.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          By connecting your Deputy account with Workativ, you
                          can{" "}
                          <a href="https://workativ.com/assistant/workflow-automation">
                            set up an automation
                          </a>{" "}
                          such that employees can use Workativ’s chatbot to
                          update their timesheet on-the-go, anytime.
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          3. Shift Management
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          By connecting your Deputy account with Workativ, you
                          can set up an automation for the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/deputy-chatbot">
                            Deputy Slack chatbot&nbsp;
                          </a>
                          such that, employees can bring it up on their
                          smartphone on Microsoft Teams or Slack and tell it
                          that they are starting/ending their shift.
                          Hassle-free.
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          How you can easily set up a Deputy chatbot with
                          Workativ
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ and Deputy and your existing apps
                          can do for you, let’s see how you can go about the
                          same.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading="lazy" src={image6} className="mb-3"></img>
                        <img loading="lazy" src={image7} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        The Benefits of HR Process Automation with Workativ
                      </h6>
                      <p class="font-section-normal-text line-height-2">
                        Now the benefits are enormous when it comes to
                        integrating and automating Deputy tasks with Workativ.
                      </p>
                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Sharing internal information
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                            Employees can quickly ask about benefits, practices,
                            etc.
                          </a>{" "}
                          and get an instant answer. The HR chatbot can also be
                          programmed to refer employees to an HR professional
                          for the more complex (or legally risky) queries such
                          as ‘recommended health plans.’
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Onboarding
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Companies using Workativ’s chatbots in HR can automate
                          the part of onboarding that deals with sharing company
                          and process knowledge with new employees.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            HR doesn’t need to answer repetitive questions from
                            new hires.&nbsp;
                          </a>
                          Employees can instead get instant answers by simply
                          typing a question to the chatbot.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Employee satisfaction
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ’s chatbot can be used to monitor employee
                          morale and satisfaction levels. It can be integrated
                          into a messaging app that employees use daily (e.g.
                          Slack) and send automated messages to gather feedback.
                          The chatbot may also be able to administer surveys and
                          analyze sentiments.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ Assistant? Try
                          Workativ Assistant today by signing up for a FREE
                          trial at
                          <a href="https://workativ.com/">
                            &nbsp;workativ.com
                          </a>{" "}
                          and experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <CookiesPoup />
            <GoToPopup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Deputy Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="deputy chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
